.header {
  width: 100%;
  height: 100px;
  background-color: red;
  color: white;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}
  
  .logo {
    height: auto;
    max-height: 70px; 
    margin-left: 10px;
    margin-right: 5px;

  }
  .header-title {
    margin-top: 15px; 
  }

  .search-form {
    margin: 0 auto; 
    max-width: calc(100% - 800px); /* Example max-width, subtracting the space for the logo and the localization text */
    position: relative;
    flex-grow: 1;
  }
  
  .search-input {
    width: 100%; 
    padding: 10px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 50px;
    outline: none;
  }
  
  .search-button {
    position: absolute;
    right: 10px; 
    top: 50%; 
    transform: translateY(-50%); /* Centers the button vertically */
    background: none; 
    border: none;
    cursor: pointer;
    outline: none;
    font-size: 24px; 
    color: #555;
  }

  .localization-text {
    margin-left: auto; 
    padding: 10px 30px; 
    color: white; 
    cursor: pointer; 
    font-weight: bold; 
  }
  
  .localization-text:hover {
    color: lightgrey; 
  }

  .header-link {
    display: flex;
    align-items: center; /* This will vertically align the logo and title if needed */
    color: inherit; /* Ensures the link uses the header's text color */
    text-decoration: none; /* Removes the underline from the link */
  }

/* Reactive Styling */
@media (max-width: 1000px) {
  .header {
    padding: 0 5px; /* Reduce padding on smaller screens */
  }
  
  .search-form {
    margin: 0 20px; 
    max-width: none; 
  }
  
  .search-input,
  .search-button {
    padding: 8px; 
  }
}
@media (max-width: 400px) {
  .search-form {
    margin: 0 10px; 
  }
}
  