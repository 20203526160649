.restaurant-list {
    height: calc(100vh - 100px);
    overflow-y: auto;
    width: 350px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    font-family: 'Arial', sans-serif;
}

.restaurant-item {
    margin-bottom: 20px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    background-color: #f9f9f9;
    border-radius: 4px;
}

.restaurant-item:hover {
    background-color: #f0f0f0;
}

.restaurant-image {
    width: 100%;
    height: auto;
    border-radius: 4px;
    margin-bottom: 8px;
}

.restaurant-name {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 4px;
}

.restaurant-description {
    font-size: 0.9em;
    color: #666;
}