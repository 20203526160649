.filter-container {
    margin-top: -95px;
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px; 
    z-index: 1000; /* Ensure it's above the map */
  }
  
  .filter-button {
    padding: 10px 20px;
    font-size: 15px;
    font-weight: bold;
    background-color: white;
    color: #333;
    border: 2px solid #ccc; /* Gives a light border to the button */
    border-radius: 40px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s, color 0.3s; /* Smoothing the transition hover effect */
  }

.selectWrapper{
    border-radius:36px;
    display:inline-block;
    overflow:hidden;
    background:#cccccc;
    border:1px solid #cccccc;
}

  .filter-button:hover {
    background-color: #f2f2f2;
    color: #000;
  }

.filter-container select {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #f1f1f1;
    font-size: 16px;
    transition: all 0.3s ease;
}

.filter-container select:focus {
    outline: none;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.25);
}

.filter-container select option {
    padding: 10px;
}
  
  /* Responsive styling */
  @media (max-width: 1000px) {
    .filter-container {
      top: 105px;
      gap: 5px;
    }
  
    .filter-button {
      padding: 8px 10px;
      font-size: 12px; 
    }
  }
  