.restaurant-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 0.6rem;
    width: 300px;
    height: 400px;
}

.restaurant-details h2 {
    padding-top: 40px;
    font-size: 20px;
    margin-bottom: 0px;
}
.restaurant-details img {
    width: 250px;
    height: auto;
}

.restaurant-details p {
    font-size: 20px;
    margin-bottom: 3px;
}